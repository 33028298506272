@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

.userDetailsAnchor {
  text-decoration: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: md;
}

.products {
  display: flex;
  flex-direction: column;
  grid-row-gap: sm;
}

.soldOutWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: sm;
}
